import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import './App.css';
import ResponsiveAppBar from './AppBar.js';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import SimpleSpeedDial from './SpeedDial';
import EartidoTypography from './eartidoType';
import NextSection from './NextSectionBtn';



import CardDisplay from './CardDisplay.js';

import OmBotak from './fonts/OmBotak.woff2';
import EartidoLogo from './images/bird_icon_eartido.png';
import IOSBtn from './images/eartido_iOS.png';
import AndroidBtn from './images/eartido_Android.png';



function App() {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  let theme = React.useMemo(
      () => createTheme({
    typography: {
      fontFamily: 'OmBotak, Helvetica',
    },
    components: {
      MuiCssBaseLine: {
        styleOverrides: `
          @font-face {
            font-family: 'OmBotak';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src:local('OmBotak'), url(${OmBotak}) format('woff2');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }`
      },
    },
    palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
          },
        }),
      [prefersDarkMode],
    );

  theme = responsiveFontSizes(theme);


  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <div className="App" >
    <ResponsiveAppBar />
      <header id="top" className="App-container">
        <div className="mccloud"><div className="bouncer"></div></div>
      <div className="container">
        <div>
      <EartidoTypography eVariant="h3" textColor='gold' borderColor='#4682B4' eContent='Welcome to...' />
      </div>
      <div>
      <IconButton className="storeIcoBtn" variant="contained" href="https://apps.apple.com/us/app/eartido/id1658394201" ><img className="storebtn linkButton" src={IOSBtn} alt="iOS Store"/></IconButton>
      <img className="eartido" src={EartidoLogo} alt="EartidoLogo"/> 
      <IconButton className="storeIcoBtn" variant="contained"  href="https://play.google.com/store/apps/details?id=com.faugdev.eartido&pli=1" ><img className="storebtn linkButton" src={AndroidBtn} alt="Android Store"/></IconButton>  
      </div>
      <div> 
      <EartidoTypography eVariant="h5" textColor='#4682B4' borderColor='white' eContent='eartido! is designed to compliment your child’s music lessons in and out of the classroom in order to develop musical comprehension, facilitate greater creativity and increase self-confidence within the expressive arts!' />
      <NextSection section="#firstSteps" />
      </div>  
      </div>    
      </header>
      <Divider />
      <div id="firstSteps" className="App-container">
      <EartidoTypography textType="sectionTitles" eVariant="h3" textColor='gold' borderColor='#4682B4' eContent='First Steps'  />
      <CardDisplay tipType={"firstSteps"} />
      <NextSection section="#whyEartido" />
      </div>
      <Divider />
      <div id="whyEartido" className="App-container">
      <EartidoTypography textType="sectionTitles" eVariant="h3" textColor='gold' borderColor='#4682B4' eContent='Why eartido?'  />
      <CardDisplay tipType={"whyEartido"} />
      <NextSection section="#tips" />
      </div>
      <div id="tips" className="App-container">
      <EartidoTypography textType="sectionTitles" eVariant="h3" textColor='gold' borderColor='#4682B4' eContent='Tips for Parents and Teachers'  />
      <CardDisplay tipType={"tips"} />
      </div>
    
    <footer>
       <div className='footer'>
       <EartidoTypography eVariant='body2' textColor= 'gold' borderColor='#4682B4' eContent='Website designed and coded by'/><a href='mailto:info@faugproductions.com?subject=Website'><EartidoTypography eVariant='body2' textColor= '#4682B4' borderColor='white' eContent='Faug Productions'/></a></div> 
       </footer>
       </div>
    <SimpleSpeedDial />
    </ThemeProvider>
  );
}

export default App;
