import * as React from 'react';
import InfoCard from './Card.js';
import {CardTips} from './CardTips.js';
import { FirstTips } from './FirstTips.js';
import {WhyEartido} from './oneSheet.js'
import Grid from '@mui/material/Grid';



export default function CardDisplay({tipType}) {

  let cardInfo ='';
  switch (tipType){
    case "firstSteps":
      cardInfo = FirstTips();
      break;
    case "tips":
      cardInfo = CardTips();
      break;
    case "whyEartido":
      cardInfo = WhyEartido();
      break;
    default:
      cardInfo = CardTips();
    
  }


  return(

      <Grid container maxWidth='90vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' sx={{marginBottom:'2rem'}} >
        {cardInfo.map((info) => (
          <Grid item xs={3} justifyContent='center' sx={{ flexGrow: 3}}>
          <InfoCard cardAva={info.ava} cardImage={info.cardImg} title={info.title} subTitle={info.subtitle} content={info.tip} />
          </Grid>
          ))}
      </Grid>

  );

}
