import LevelOne from './images/screenshots/Level1.png';
import Menu from './images/screenshots/Menu.png';
import LevelThree from './images/screenshots/Level3.png';
import LevelSeven from './images/screenshots/Level7.png';
import Practice from './images/screenshots/Practice.png';
import DoAvatar from './images/avatars/do.png';
import ReAvatar from './images/avatars/re.png';
import MiAvatar from './images/avatars/mi.png';
import FaAvatar from './images/avatars/fa.png';
import SolAvatar from './images/avatars/sol.png';




const cardInfo = [{
  ava: DoAvatar,
  cardImg: LevelOne,
  title: 'High and Low',
  subtitle: '',
  tip: `Levels 1, 2 and 3 are usually the place to begin with a student that is new to ear training. If the student is very young (2-4 years), level 1 might work well. Begin by asking the student ‘is it a high note or a low
        note?’. Once they
        progress to level 2, you can ask which note sounds different, while discussing the fact that the two do’s sound the same, but one is high and one is low. You’ll find that they can very quickly get the hang of this and progress to level 3.
`
}, {
  ava: ReAvatar,
  cardImg: Menu,
  subtitle: '',
  title: `Practice fast, you'll learn slow; Practice slow, you'll learn fast!`,
  tip: `Your student might ask to play one of the highest levels straight away. It is important
        to let them know that they’ll get there soon, but it is better to stick to the earlier levels until they can consistently achieve correct answers.
    `
}, {
  ava: MiAvatar,
  cardImg: LevelThree,
  title: 'Starting out',
  subtitle: '',
  tip: `If the student is a little older (5-8 years), it may be best to start straight away with Level
        3. Again, it is important to ask the student to notice that low and high do are the ‘same, but one is high and one is low’. If you get the sense that the student is progressing rapidly and might need an added stimulus in order to keep them
        working on a lower level, try adding the countdown timer - the added challenge of finding the correct pitch against the clock usually helps them focus for longer!
    `
}, {
  ava: FaAvatar,
  cardImg: LevelSeven,
  title: `Why eartido!?`,
  subtitle: '',
  tip: `As with any learning situation, it is always useful to emphasise the ‘why?’ of what the student is doing with eartido!
        Ask the student what their favourite song is and then explain that with more practice of eartido! they’ll soon be able to figure out that song on their instrument all by themselves.
        If they have reached level 7, you can then put this to the test!`
}, {
  ava: SolAvatar,
  cardImg: Practice,
  title: '(Perfect) Practice makes Perfect!',
  subtitle: '',
  tip: `Our practice area is always available without subscription and is a great place for the student to hear how the notes should sound and try playing melodies!`
}];
export function CardTips() {


  return cardInfo;
};
