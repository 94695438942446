import Menu from './images/screenshots/Menu.png';
import LevelOne from './images/screenshots/Level1.png';
import LevelThree from './images/screenshots/Level3.png';
import LevelSeven from './images/screenshots/Level7.png';
import Practice from './images/screenshots/Practice.png';
import HighScore from './images/screenshots/HighScore.png';
import SolAvatar from './images/avatars/sol.png';
import LaAvatar from './images/avatars/la.png';
import TiAvatar from './images/avatars/ti.png';
import BorisImg  from './images/avatars/endorsements/BR.png';
import ClaireImg  from './images/avatars/endorsements/CG.jpg';
import FrancescaImg  from './images/avatars/endorsements/FD.png';
import JonImg  from './images/avatars/endorsements/JS.png';
import RichardImg  from './images/avatars/endorsements/RM.png';



const cardInfo = [
    {
        ava: SolAvatar,
        cardImg: Menu,
        title: `The Problem:`,
        subtitle: '',
        tip: `Many parents would like their child to experience music
        lessons, but this can often be exclusive or prohibitively expensive.
        In an age when we have access to every piece of music that has ever been recorded, wouldn’t it be fantastic if children could learn music on their own terms?
        Classroom Primary teachers are increasingly expected to teach music classes having had little to no prior musical training.`

    }, {
        ava: LaAvatar,
        cardImg: LevelThree,
        title: 'Our Solution:',
        subtitle: '',
        tip: `eartido! supplements the child’s music lessons in
        and out of the classroom in order to improve musical comprehension, facilitate greater creativity and promote self- confidence within the expressive arts. Regular use of eartido allows the students to ‘play what they hear’ and learn their favourite music by ear.`
    }, {
        ava: TiAvatar,
        cardImg: LevelSeven,
        title: 'What is Ear Training?',
        subtitle: '',
        tip: `The ability to distinguish between different musical notes, Ear training is a crucial, yet often overlooked foundational skill for any musical education and is useful across all instruments and genres.`
    }, {
        ava: RichardImg,
        cardImg: LevelOne,
        title: 'Dr Richard Michael',
        subtitle: 'Music Educator',
        tip: `“eartido! is a fantastic app for teaching children the very fundamentals of music, no matter their instrument!”`
    },
 
    {
        ava: ClaireImg,
        cardImg: LevelSeven,
        title: 'Claire Gallagher',
        subtitle: 'Piano Instructor',
        tip: `“eartido! is a great tool for learning to hear pitches and making it fun in the process!”`
    },
    {
        ava: BorisImg,
        cardImg: HighScore,
        title: 'Boris Richir',
        subtitle: 'Founder, United Dance',
        tip: `“Our students have a great time learning ear training with eartido!"`
    },
    {
        ava: FrancescaImg,
        cardImg: Menu,
        title: 'Francesca Dardani',
        subtitle: 'Violinist (Madonna, Stevie Wonder)',
        tip: `“My students love eartido! and I’ll be using this with my son, too, when the time comes!”`
    },
    {
        ava: JonImg,
        cardImg: Practice,
        title: 'Jon Sosin',
        subtitle: 'Guitarist (Casey Musgraves, Lukas Graham)',
        tip: `"eartido! goes a long way to providing a solid framework from which any student could learn, no matter the instrument”`
    }
];
export function WhyEartido() {


  return cardInfo;
};
