import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


const actions = [
  { icon: <MailOutlineIcon />, name: 'Send us a note!', loc: "mailto:info@faugproductions.com?subject=Question%20for%20eartido!" },
  { icon: <TipsAndUpdatesIcon />, name: 'Read some tips for Parents and Teachers!', loc: "#tips", class: "linkButton umami--click--Teachers-button" },
  {icon: <QuestionMarkIcon />, name: 'Why choose Ear Training with eartido!?', loc: "#whyEartido", class: "linkButton umami--click--Why-button" },
  {icon: <PlaylistAddCheckIcon />, name: 'First Steps with eartido!', loc: "#firstSteps", class: "linkButton umami--click--FirstSteps-button" },
  { icon: <AndroidIcon />, name: 'Find us on the Android Store!', loc: "https://play.google.com/store/apps/details?id=com.faugdev.eartido&pli=1", class: "linkButton umami--click--Android-button" },
  { icon: <AppleIcon />, name: 'Find us on the iOS Store!', loc: "https://apps.apple.com/us/app/eartido/id1658394201", class: "linkButton umami--click--iOS-button", },
  { icon: <ArrowUpwardIcon />, name: 'Back to the top!', loc: "#top" }  
];

export default function SimpleSpeedDial() {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SpeedDial 
        ariaLabel="Links"
        sx={{ position: 'fixed', bottom: "1rem", right: "1rem" }}
        icon={<SpeedDialIcon />}
        FabProps={
          {
          sx: {
            bgcolor: 'transparent',
            backdropFilter:"blur(20px)",
            '&:hover': {
              bgcolor: 'rgba(50,50,50,0.3)',
            }
        }
      }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            href={action.loc}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
