import * as React from 'react';
import Typography from '@mui/material/Typography';

export default function EartidoTypography({textType, eVariant, textColor, borderColor, eContent}) {
    return(
        <div className={textType}>
        <Typography variant={eVariant} color={textColor} sx={{
            textShadow: `-2px -2px 0 ${borderColor},
                        0   -2px 0 ${borderColor},
                        2px -2px 0 ${borderColor},
                        2px  0   0 ${borderColor},
                        2px  2px 0 ${borderColor},
                        0    2px 0 ${borderColor},
                        -2px  2px 0 ${borderColor},
                        -2px  0   0 ${borderColor},
                        3px 3px 5px rgba(0,0,0,0.5)`
    }}>{eContent}</Typography>
    </div>
    )
}