import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function NextSection({section}){
    return(
        <IconButton href={section}>
        <ExpandMoreIcon className="downButton" sx={{fontSize:'5rem'}} />
      </IconButton>
    )
}