import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import EartidoTypography from './eartidoType';


import BirdLogo from './images/birdGif.gif';
import FaugLogo from './images/faugdev_sig.png'

const pages = [{link:'iOS', class: "linkButton umami--click--iOS-button", loc:'https://apps.apple.com/us/app/eartido/id1658394201', tip: 'Find us on the iOS Store!'},
                {link:'Android', class: "linkButton umami--click--Android-button", loc:'https://play.google.com/store/apps/details?id=com.faugdev.eartido&pli=1', tip: 'Find us on the Android Store!'}, 
                {link:'First Steps', class: "linkButton umami--click--FirstSteps-button", loc:'/#firstSteps', tip: 'First Steps with eartido!'}, 
                {link:'Why eartido?', class: "linkButton umami--click--Why-button", loc:'/#whyEartido', tip: 'Why choose Ear Training with eartido!?'}, 
                {link:'Tips for Parents and Teachers', class: "linkButton umami--click--Teachers-button", loc:'/#tips', tip: 'Read some tips for Parents and Teachers!'}];


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };



  return (
    <AppBar position="fixed" color="transparent" enableColorOnDark sx={{backdropFilter:"blur(20px)"}}>
      <Container maxWidth="xl" >
        <Toolbar disableGutters>
        <img className="logo"  src={BirdLogo} alt="EartidoLogo" />
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="#top"
            sx={{
              mr: 2,
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              letterSpacing: '.3rem',
              color: 'gold',
              textShadow: `-2px -2px 0 #4682B4,
                            0   -2px 0 #4682B4,
                            2px -2px 0 #4682B4,
                            2px  0   0 #4682B4,
                            2px  2px 0 #4682B4,
                            0    2px 0 #4682B4,
                            -2px  2px 0 #4682B4,
                            -2px  0   0 #4682B4,
                            3px 3px 5px rgba(0,0,0,0.5)`,

              textDecoration: 'none'
              
            }}
          >
          ‎ eartido! ‎
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{color:"gold"}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection:'column'
              }}
            >
              {pages.map((page) => (
                <MenuItem component={Button} href={page.loc} key={page.link} onClick={handleCloseNavMenu}>
                  <EartidoTypography eVariant='body2' textColor='#4682B4'  eContent={page.link} />
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="#top"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              letterSpacing: '.3rem',
              textShadow: `-2px -2px 0 #4682B4,
                            0   -2px 0 #4682B4,
                            2px -2px 0 #4682B4,
                            2px  0   0 #4682B4,
                            2px  2px 0 #4682B4,
                            0    2px 0 #4682B4,
                            -2px  2px 0 #4682B4,
                            -2px  0   0 #4682B4,
                            3px 3px 5px rgba(0,0,0,0.5)`,
              color: 'gold',
              textDecoration: 'none',
            }}
          >
            ‎ eartido! ‎
          </Typography>
          <Box  sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Tooltip title={page.tip}>
              <Button
              className={page.class}
                key={page.link}
                onClick={handleCloseNavMenu}
                href={page.loc}
                sx={{ my: 3,
                  color: 'white',
                  textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
                  display: 'block',
                  boxShadow:'2px 2px 4px rgba(0,0,0,0.3)',
                  borderRadius: 1,
                   marginLeft:'2rem'
                  }}

              >
               <EartidoTypography eVariant='body2' textColor='gold'  eContent={page.link} /> 
              </Button>
              </Tooltip>
            ))}
          </Box>
          <Tooltip title='Send us a note!'>
          <IconButton  sx={{textDecoration:'none', color: 'gold', display: {xs: 'none', md: 'flex'}}} href="mailto:info@faugproductions.com?subject=Question%20for%20eartido!">
          <MailOutlineIcon className="linkButton" sx={{fontSize:  '3rem'}} />
          </IconButton>
          </Tooltip>
          <Tooltip placement="bottom-end" title='Visit Faug Productions (creators of eartido!)'>
          <IconButton variant="contained" href="https://www.faugproductions.com" ><img className="faugbtn linkButton" src={FaugLogo} alt="Faug Productions Logo"/></IconButton>
          </Tooltip>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
