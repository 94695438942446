import Menu from './images/screenshots/Menu.png';
import LevelThree from './images/screenshots/Level3.png';
import LevelSeven from './images/screenshots/Level7.png';
import Practice from './images/screenshots/Practice.png';
import DoAvatar from './images/avatars/do.png';
import ReAvatar from './images/avatars/re.png';
import MiAvatar from './images/avatars/mi.png';
import FaAvatar from './images/avatars/fa.png';



const cardInfo = [
    {
        ava: DoAvatar,
        cardImg: Menu,
        title: `Main Menu`,
        subtitle: '',
        tip: `The first screen that you'll see is our main menu. From here, you can adjust settings such as the volume, note names as well as access to the subscription purchase area. You'll also find our instructions on playing eartido!`

    }, {
        ava: ReAvatar,
        cardImg: Practice,
        title: 'Getting to know eartido!',
        subtitle: '',
        tip: `The perfect way to get acquainted with eartido is in our practice area - press the birds or note names and hear them sing!`
    }, {
        ava: MiAvatar,
        cardImg: LevelThree,
        title: 'Subscription Screen',
        subtitle: '',
        tip: `We've added a 'parent gate' so that your budding student can't make purchases by mistake! Press start, then follow the colour coded instructions to access our purchase page. We've created the subscription to eartido! so that you'll never see ads or give up personal data while using eartido! You'll also receive every update to the app as we continue to improve it!`
    }, {
        ava: FaAvatar,
        cardImg: LevelSeven,
        title: '3..2..1..eartido!',
        subtitle: '',
        tip: ` Once you have your subscription set up, it's time to start exploring eartido! For more hints & tips on learning with eartido! Please see the section below.`
    }
];
export function FirstTips() {


  return cardInfo;
};
