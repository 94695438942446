import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';



export default function InfoCard({cardAva, cardImage, title, subTitle, content}) {

  return (
    <Card className="card" raised sx={{
      border:'solid',
      borderColor:'#4682B4',
      borderRadius: '1rem',
      backgroundColor:'transparent',
      backdropFilter:"blur(20px)",
      overflow: 'visible',
      margin: '1rem',
      position: 'relative'}}>
        <Avatar src={cardAva} size='large' sx={{position: 'absolute', 
                                                top: '-2rem', 
                                                left: '-2rem', 
                                                width: '5rem', 
                                                height: '5rem', 
                                                border: 'solid', 
                                                borderColor: '#4682B4', 
                                                backgroundColor: 'rgba(255,255,255,0.4)', 
                                                backdropFilter:"blur(30px)"}}/>
        <CardMedia
        component="img"
        width='100%'
        height='100%'
        image={cardImage}
        alt={title}
        sx={{borderRadius:'0.5rem'}}
        />
        <CardContent >
        
        <Typography variant="h6" component="div" color="gold" sx={{textShadow: `-2px -2px 0 #4682B4,
                            0   -2px 0 #4682B4,
                            2px -2px 0 #4682B4,
                            2px  0   0 #4682B4,
                            2px  2px 0 #4682B4,
                            0    2px 0 #4682B4,
                            -2px  2px 0 #4682B4,
                            -2px  0   0 #4682B4,
                            3px 3px 5px rgba(0,0,0,0.5)`}}>
          {title}
        </Typography>
        <Typography variant="body1" color="#4682B4">
          {subTitle}
        </Typography>
        <Typography variant="body2" color="black">
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}
